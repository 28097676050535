.donate {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.75s ease-out;

  &[aria-hidden='false'] {
    max-height: 500px;
  }
}

.donate__wrapper {
  background-color: #0a66c2;
  border-radius: 15px;
  padding: 1.5rem;
  color: white;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.donate__heading {
  margin: 0;
}

.donate__text {
  margin: 0;
  text-align: left;
  font-style: italic;
}

.donate__link {
  display: inline-flex;
  margin: auto;
}
