.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__links {
  display: inline-flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.footer__link {
  color: black;
  flex-shrink: 0;
  text-decoration: underline;
  cursor: pointer;

  &:visited {
    color: black;
  }

  &:hover {
    text-decoration: none;
  }
}
