.add-sponsor {
  display: none;

  &:has(+ ins.adsbygoogle[data-ad-status='filled']) {
    display: inline-block;
  }
}

ins.adsbygoogle[data-ad-status='unfilled'] {
  display: none !important;
}

#google_esf {
  border: none;
}
