$primaryText: #fff;
$primaryBlue: #0a66c2;
$secondaryBackround: #f4ede4;
$headerHeight: 100px;
$footerHeight: 80px;
$mainPaddingBottom: 2rem;

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: sans-serif;
  margin: 0;
  font-size: 16px;
  height: 100%;
}

.page-layout {
  display: grid;
  grid-template-rows: $headerHeight auto minmax($footerHeight, max-content);
  grid-template-columns: 100%;
  min-height: 100%;
  overflow: hidden;
}

.main {
  padding-bottom: $mainPaddingBottom;
}

ul {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;

  @media screen and (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.content-wrapper--fixed {
  @extend .content-wrapper;
  max-width: 1200px;
  flex-basis: 100%;
}

.content-wrapper--blue {
  background-color: $primaryBlue;
}

.content-wrapper--grey {
  background-color: $secondaryBackround;
}

.c-align {
  text-align: center;
}

.main--full-size {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $secondaryBackround;
}

.loader {
  display: none;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  vertical-align: text-bottom;
  border: 0.25rem solid $primaryText;
  border-right-color: transparent !important;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;

  &.is-active {
    display: block;
  }
}

.article {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
  line-height: 1.5;

  h2 {
    margin-top: 0;
  }
}

.error {
  color: $primaryText;

  &[aria-hidden='true'] {
    display: none;
  }

  a {
    color: $primaryText;

    &:visited {
      color: $primaryText;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

@import './partials/form.scss';
@import './partials/header.scss';
@import './partials/waves.scss';
@import './partials/buttons.scss';
@import './partials/footer.scss';
@import './partials/ctaContent.scss';
@import './partials/dialog.scss';
@import './partials/donate.scss';
@import './partials/ads.scss';

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  $primaryText-space: nowrap;
  border: 0;
}

.hidden {
  display: none;
}

.scroll-lock {
  overflow: hidden;
}
