.say-my-name {
  font-size: 3rem;
  color: $primaryText;
  text-align: center;
}

.say-my-name__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  gap: 2rem;
  background-color: $primaryBlue;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.say-my-name-form__wrapper {
  @extend .say-my-name__wrapper;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: auto minmax(230px, auto) auto;
  }
}

.say-my-name-form {
  background-color: $primaryBlue;
  margin: 0 auto;

  input,
  select {
    height: 40px;
    margin: 0;
    font-size: 2rem;
    color: $primaryText;
    border: none;
    border-bottom: 2px solid $primaryText;
    background-color: transparent;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;

    &::placeholder {
      color: $primaryText;
    }

    option {
      color: initial;
      background-color: initial;
      font-size: initial;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }
  }

  .error {
    text-align: center;
  }
}

.say-my-name-form__item {
  width: 100%;
  max-width: 370px;
  position: relative;

  @media screen and (min-width: 768px) {
    width: initial;
    max-width: 100%;
  }
}

.say-my-name-form__select-arrow {
  color: $primaryText;
  width: 34px;
  height: 34px;
  position: absolute;
  right: -4px;
  top: 20px;
  pointer-events: none;
}

.say-my-name-form__label {
  display: block;
  color: $primaryText;
  font-size: 0.9rem;
}

.say-my-name-form__share-wrapper {
  display: grid;
  justify-items: center;
  gap: 1rem;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  color: #fff;

  &[aria-hidden='false'] {
    max-height: 90px;
  }
}

.say-my-name-form__share-link {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;

  small {
    display: block;
  }
}
