header {
  display: flex;
  color: $primaryBlue;
  background-color: #fff;
  z-index: 1;
  height: $headerHeight;
  position: relative;
}

.header__logo {
  display: inline-flex;
  gap: 15px;
  align-items: center;
  height: 35px;

  @media screen and (min-width: 768px) {
    height: 50px;
    gap: 20px;
  }

  svg {
    height: 100%;
    width: auto;
  }

  .header__logo-text {
    height: 75%;
  }
}

.header__main-link {
  display: inline-block;
}

.main-heding {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $secondaryBackround;
}

.main-heding__header {
  font-size: 4rem;
  margin-top: 0;
}

.main-heding__list {
  font-size: 1.125rem;
  margin-bottom: 40px;

  li {
    margin: 1rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
