html {
  box-sizing: border-box;
  height: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  font-family: sans-serif;
  font-size: 16px;
}

.page-layout {
  min-height: 100%;
  grid-template-rows: 100px auto minmax(80px, max-content);
  grid-template-columns: 100%;
  display: grid;
  overflow: hidden;
}

.main {
  padding-bottom: 2rem;
}

ul {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

.content-wrapper, .content-wrapper--fixed {
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 768px) {
  .content-wrapper, .content-wrapper--fixed {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.content-wrapper--fixed {
  max-width: 1200px;
  flex-basis: 100%;
}

.content-wrapper--blue {
  background-color: #0a66c2;
}

.content-wrapper--grey {
  background-color: #f4ede4;
}

.c-align {
  text-align: center;
}

.main--full-size {
  width: 100%;
  background-color: #f4ede4;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loader {
  width: 4rem;
  height: 4rem;
  vertical-align: text-bottom;
  border: .25rem solid #fff;
  border-radius: 50%;
  padding: .5rem;
  animation: .75s linear infinite spinner-border;
  display: none;
  border-right-color: #0000 !important;
}

.loader.is-active {
  display: block;
}

.article {
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 1.5;
}

.article h2 {
  margin-top: 0;
}

.error {
  color: #fff;
}

.error[aria-hidden="true"] {
  display: none;
}

.error a, .error a:visited {
  color: #fff;
}

.error a:hover {
  text-decoration: none;
}

.say-my-name {
  color: #fff;
  text-align: center;
  font-size: 3rem;
}

.say-my-name__wrapper, .say-my-name-form__wrapper {
  min-height: 64px;
  background-color: #0a66c2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .say-my-name__wrapper, .say-my-name-form__wrapper {
    flex-direction: row;
  }

  .say-my-name-form__wrapper {
    grid-template-columns: auto minmax(230px, auto) auto;
    display: grid;
  }
}

.say-my-name-form {
  background-color: #0a66c2;
  margin: 0 auto;
}

.say-my-name-form input, .say-my-name-form select {
  height: 40px;
  color: #fff;
  width: 100%;
  appearance: none;
  background-color: #0000;
  border: none;
  border-bottom: 2px solid #fff;
  margin: 0;
  font-size: 2rem;
}

.say-my-name-form input::placeholder, .say-my-name-form select::placeholder {
  color: #fff;
}

.say-my-name-form input option, .say-my-name-form select option {
  color: initial;
  background-color: initial;
  font-size: initial;
}

.say-my-name-form input:-webkit-autofill, .say-my-name-form input:-webkit-autofill:hover, .say-my-name-form input:-webkit-autofill:focus, .say-my-name-form input:-webkit-autofill:active, .say-my-name-form select:-webkit-autofill, .say-my-name-form select:-webkit-autofill:hover, .say-my-name-form select:-webkit-autofill:focus, .say-my-name-form select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out;
  -webkit-text-fill-color: #fff !important;
}

.say-my-name-form .error {
  text-align: center;
}

.say-my-name-form__item {
  width: 100%;
  max-width: 370px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .say-my-name-form__item {
    width: initial;
    max-width: 100%;
  }
}

.say-my-name-form__select-arrow {
  color: #fff;
  width: 34px;
  height: 34px;
  pointer-events: none;
  position: absolute;
  top: 20px;
  right: -4px;
}

.say-my-name-form__label {
  color: #fff;
  font-size: .9rem;
  display: block;
}

.say-my-name-form__share-wrapper {
  max-height: 0;
  color: #fff;
  justify-items: center;
  gap: 1rem;
  transition: max-height .3s ease-out;
  display: grid;
  overflow: hidden;
}

.say-my-name-form__share-wrapper[aria-hidden="false"] {
  max-height: 90px;
}

.say-my-name-form__share-link {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.say-my-name-form__share-link small {
  display: block;
}

header {
  color: #0a66c2;
  z-index: 1;
  height: 100px;
  background-color: #fff;
  display: flex;
  position: relative;
}

.header__logo {
  height: 35px;
  align-items: center;
  gap: 15px;
  display: inline-flex;
}

@media screen and (min-width: 768px) {
  .header__logo {
    height: 50px;
    gap: 20px;
  }
}

.header__logo svg {
  height: 100%;
  width: auto;
}

.header__logo .header__logo-text {
  height: 75%;
}

.header__main-link {
  display: inline-block;
}

.main-heding {
  background-color: #f4ede4;
  padding-top: 40px;
  padding-bottom: 40px;
}

.main-heding__header {
  margin-top: 0;
  font-size: 4rem;
}

.main-heding__list {
  margin-bottom: 40px;
  font-size: 1.125rem;
}

.main-heding__list li {
  margin: 1rem 0;
}

.main-heding__list li:last-child {
  margin-bottom: 0;
}

.sound-waves {
  width: 100%;
}

.sound-waves__group {
  width: 100%;
  height: 70px;
  position: relative;
  overflow-x: hidden;
}

.sound-waves__group--top {
  background-color: #f4ede4;
}

.sound-waves__group--bottom {
  inset: auto 0 0;
  transform: rotate(180deg);
}

.sound-waves__wave {
  width: 90000px;
  height: 100%;
  opacity: 1;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%230a66c2'/%3E%3C/svg%3E");
  position: absolute;
  left: -100vw;
  transform: translate3d(0, 0, 0);
}

.sound-waves--active .sound-waves__wave {
  animation: 20s linear -30s infinite wave;
}

.sound-waves__wave:nth-of-type(2) {
  opacity: .5;
  bottom: 0;
  transform: translateX(-10%);
}

.sound-waves--active .sound-waves__wave:nth-of-type(2) {
  animation: 180s linear infinite reverse wave;
}

.sound-waves__wave:nth-of-type(3) {
  opacity: .3;
  bottom: 0;
  transform: translateX(-15%);
}

.sound-waves--active .sound-waves__wave:nth-of-type(3) {
  animation: 40s linear -10s infinite wave;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

button {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.button-action, .button-secondary, .button-primary {
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 40px;
  border: 2px solid #0000;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  transition: background-color .2s ease-in-out;
  display: flex;
}

.button-action svg, .button-secondary svg, .button-primary svg {
  height: 100%;
  width: auto;
}

.button-action:focus-visible, .button-secondary:focus-visible, .button-primary:focus-visible {
  outline-offset: 2px;
  outline: 2px dotted #0000;
  box-shadow: 0 0 0 1px #fff, 0 0 0 4px #0a66c2;
}

.button-primary {
  color: #fff;
  background-color: #0a66c2;
}

.button-secondary {
  color: #000;
  background-color: #fff;
  border-color: #0a66c2;
}

.button-secondary:hover {
  background-color: #f4ede4;
}

.button-secondary[aria-selected="true"] {
  color: #fff;
  background-color: #0a66c2;
}

.button-action {
  width: 40px;
  border-radius: 50%;
  padding: .5rem;
}

.button-action:hover {
  background-color: #f4ede4;
}

.play-button {
  height: 4rem;
  width: 4rem;
  color: #fff;
  flex-shrink: 0;
}

.play-button svg {
  height: 100%;
  width: 100%;
}

.footer {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__links {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  display: inline-flex;
}

.footer__link {
  color: #000;
  cursor: pointer;
  flex-shrink: 0;
  text-decoration: underline;
}

.footer__link:visited {
  color: #000;
}

.footer__link:hover {
  text-decoration: none;
}

.cta-contents {
  grid-template-columns: minmax(0, 400px);
  justify-content: center;
  gap: 40px;
  margin-top: 3rem;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  display: grid;
}

.cta-contents svg {
  width: 80px;
  height: auto;
  margin: auto auto 1rem;
  display: block;
}

.cta-contents strong {
  display: block;
}

@media screen and (min-width: 768px) {
  .cta-contents {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.dialog[aria-hidden="true"] {
  display: none;
}

.dialog__overlay {
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.dialog__body {
  max-width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
}

@media screen and (min-width: 768px) {
  .dialog__body {
    padding: 2rem;
  }
}

.dialog__label {
  margin: 0;
  padding: 0;
}

.dialog__label-container {
  justify-content: space-between;
  gap: .5rem;
  margin-bottom: 1rem;
  display: flex;
}

.dialog__content[aria-hidden="true"] {
  display: none;
}

.dialog__loader {
  border-color: #000;
  margin: auto;
}

.share__buttons {
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.share__link {
  color: inherit;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  display: inline-block;
  overflow: hidden;
}

.share__link:hover {
  text-decoration: none;
}

.share__content {
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.share__content--gaps {
  gap: 2rem;
}

.donate {
  max-height: 0;
  transition: max-height .75s ease-out;
  overflow: hidden;
}

.donate[aria-hidden="false"] {
  max-height: 500px;
}

.donate__wrapper {
  color: #fff;
  text-align: center;
  background-color: #0a66c2;
  border-radius: 15px;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1.5rem;
  display: flex;
}

.donate__heading {
  margin: 0;
}

.donate__text {
  text-align: left;
  margin: 0;
  font-style: italic;
}

.donate__link {
  margin: auto;
  display: inline-flex;
}

.add-sponsor {
  display: none;
}

.add-sponsor:has( + ins.adsbygoogle[data-ad-status="filled"]) {
  display: inline-block;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

#google_esf {
  border: none;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.hidden {
  display: none;
}

.scroll-lock {
  overflow: hidden;
}

/*# sourceMappingURL=cookies.f9069701.css.map */
