.dialog {
  &[aria-hidden='true'] {
    display: none;
  }
}

.dialog__overlay {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1;
}

.dialog__body {
  padding: 1rem;
  background-color: white;
  max-width: 100%;
  border-radius: 4px;

  @media screen and (min-width: 768px) {
    padding: 2rem;
  }
}

.dialog__label {
  padding: 0;
  margin: 0;
}

.dialog__label-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.dialog__content {
  &[aria-hidden='true'] {
    display: none;
  }
}

.dialog__loader {
  border-color: #000;
  margin: auto;
}

.share__buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.share__link {
  font-size: 1.2rem;
  color: inherit;
  max-width: 400px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }
}

.share__content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}

.share__content--gaps {
  gap: 2rem;
}
