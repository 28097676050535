.sound-waves {
  width: 100%;
}
.sound-waves__group {
  width: 100%;
  height: 70px;
  overflow-x: hidden;
  position: relative;
}

.sound-waves__group--top {
  background-color: $secondaryBackround;
}

.sound-waves__group--bottom {
  top: 0;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  transform: rotate(180deg);
}

.sound-waves__wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%230a66c2'/%3E%3C/svg%3E");
  position: absolute;
  width: 90000px;
  height: 100%;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  left: -100vw;

  .sound-waves--active & {
    animation: wave 20s -30s linear infinite;
  }
}

.sound-waves__wave:nth-of-type(2) {
  bottom: 0;
  transform: translateX(-10%);
  opacity: 0.5;

  .sound-waves--active & {
    animation: wave 180s linear reverse infinite;
  }
}

.sound-waves__wave:nth-of-type(3) {
  bottom: 0;
  opacity: 0.3;
  transform: translateX(-15%);
  .sound-waves--active & {
    animation: wave 40s -10s linear infinite;
  }
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(-50%);
  }
}

//
// Rotating border
//

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
