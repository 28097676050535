.cta-contents {
  font-size: 18px;
  font-weight: 700;
  margin-top: 3rem;
  line-height: 24px;
  display: grid;
  grid-template-columns: minmax(0, 400px);
  justify-content: center;
  gap: 40px;

  svg {
    width: 80px;
    height: auto;
    margin: auto;
    display: block;
    margin-bottom: 1rem;
  }

  strong {
    display: block;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
