button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

%button {
  display: flex;
  justify-content: center;
  font-weight: 400;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  text-decoration: none;
  line-height: 1.5;
  border-radius: 0.25rem;
  height: 40px;
  gap: 0.5rem;
  transition: background-color 0.2s ease-in-out;

  svg {
    height: 100%;
    width: auto;
  }

  &:focus-visible {
    box-shadow: 0 0 0 1px #fff, 0 0 0 4px $primaryBlue;
    outline: 2px dotted transparent;
    outline-offset: 2px;
  }
}

.button-primary {
  @extend %button;
  color: #fff;
  background-color: $primaryBlue;
}

.button-secondary {
  @extend %button;
  color: #000;
  background-color: #fff;
  border-color: $primaryBlue;

  &:hover {
    background-color: $secondaryBackround;
  }

  &[aria-selected='true'] {
    color: #fff;
    background-color: $primaryBlue;
  }
}

.button-action {
  @extend %button;
  width: 40px;
  padding: 0.5rem;

  border-radius: 50%;

  &:hover {
    background-color: $secondaryBackround;
  }
}

.play-button {
  flex-shrink: 0;
  height: 4rem;
  width: 4rem;
  color: $primaryText;

  svg {
    height: 100%;
    width: 100%;
  }
}
